import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { v4 as uuid } from 'uuid'
import { rtcConfig, trackerUrls } from '../../config'
import { RoomContext, ShellContext, SettingsContext } from '../../contexts'
import { encryptionService as encryptionServiceInstance } from '../../services'
import { useRoom } from '../../hook'
import {
  RoomAudioControls,
  RoomVideoControls,
  RoomScreenShareControls,
  RoomFileUploadControls,
  RoomVideoDisplay,
  ScreenRecorderControls,
  SharePasswordControls,
  ParticipantsControls,
  RoomShowMessagesControls,
} from '../../controls'
import { Page } from '../structure'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { SearchAllUsers } from '../../apis/user.api'
import { ProfileModel } from '../../models/profile.model'
import { isDesktop } from '../../utils/detect-screen'
import { CButton, CModal } from '../mui'
import { ReactComponent as Leave } from '../../assets/svg/leave.svg'
import { ReactComponent as Warning } from '../../assets/svg/warning.svg'
import { Button } from '@mui/material'

const LeaveCall = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;

  > .icon {
    background: ${({ theme }) => theme.red120};
    border-radius: 100%;
    width: 72px;
    height: 72px;
    padding: 24px;
    text-align: center;
    margin: 0 auto 0 auto;
  }

  > h3 {
    color: ${({ theme }) => theme.white100};
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    margin-top: 20px;
  }

  > p {
    color: ${({ theme }) => theme.white100};
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: justify;
    margin-top: 20px;
  }
`

const Footer = styled.div`
  background: transparent;
  width: 100% !important;
  display: grid;
  grid-template-columns: ${isDesktop() ? '1fr 1fr 1fr' : '1fr 1fr'};
  margin-top: 20px;

  > .right {
    display: flex;
    justify-self: start;
  }

  > .center {
    display: flex;
    justify-self: center;
  }

  > .left {
    display: flex;
    justify-self: end;
  }
`

const FooterMenu = styled.div`
  position: absolute;
  bottom: 0;
  width: 95% !important;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  justify-self: center;
  background: rgba(0, 0, 0, 0.75);
  margin-left: 10px;
  /* gap: 32px; */
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  z-index: 100;
  transition: 1s all;

  > .line {
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 0 auto 0 auto;
    padding-left: 45%;
    cursor: pointer;
    padding-top: 24px;
    padding-bottom: 24px;

    > .green {
      width: 36px;
      height: 5px;
      background: rgba(57, 219, 178, 1);
      border-radius: 10px;
    }
  }

  > .box {
    width: 400px;
    margin: 0 auto 20px auto;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    > .flex {
      flex: 1 0 0;
      margin-left: -30px;
    }
  }
  > .boxMobile {
    width: 400px;
    margin: 0 auto 20px auto;
    display: flex;
    align-items: center;
    justify-content: space-around;

    > .flex1 {
      position: relative;
      right: 30px;
    }
    > .flex2 {
      position: relative;
      right: 10px;
    }
  }
  
`

export interface RoomProps {
  appId?: string
  getUuid?: typeof uuid
  password?: string
  roomId: string
  userId: string
  encryptionService?: typeof encryptionServiceInstance
  rPass?: string
  currentWindow: Window
}

export function Room({
  appId = `${encodeURI(window.location.origin)}_tellus`,
  getUuid = uuid,
  encryptionService = encryptionServiceInstance,
  roomId,
  password,
  userId,
  rPass,
  currentWindow,
}: RoomProps) {
  const location = useLocation()
  const settingsContext = useContext(SettingsContext)
  const { showActiveTypingStatus, publicKey } =
    settingsContext.getUserSettings()
  const {
    isMessageSending,
    handleInlineMediaUpload,
    handleMessageChange,
    messageLog,
    peerRoom,
    roomContextValue,
    sendMessage,
    sendRecordingAlert,
    showVideoDisplay,
    recordingAlertLog,
  } = useRoom(
    {
      appId,
      trackerUrls,
      rtcConfig,
      password,
      trackerRedundancy: 4,
    },
    {
      roomId,
      userId,
      getUuid,
      publicKey,
      encryptionService,
    }
  )

  const handleMessageSubmit = async (message: string) => {
    await sendMessage(message)
  }

  const handleRecordingAlertSubmit = async (message: string) => {
    await sendRecordingAlert(message)
  }

  const {
    peerList,
    peerConnectionTypes,
    audioState,
    peerAudios,
    connectionTestResults,
    showAlert,
  } = useContext(ShellContext)

  const [usersLoading, setUsersLoading] = useState<boolean>(true)
  const [isRoomFull, setIsRoomFull] = useState<boolean>(true)
  const [users, setUsers] = useState<Array<ProfileModel>>([])
  useEffect(() => {
    setUsersLoading(true); // Set loading state to true before fetching users

    // Fetch all users with pagination, 30 users per page
    SearchAllUsers({ perPage: 30, cursor: '' }).then(result => {
      if (result) setUsers(result.data.users); // Update users state with the fetched users
      setUsersLoading(false); // Set loading state to false after fetching
    });
  }, []);

  const [isSpeakingToRoom, setIsSpeakingToRoom] = useState<boolean>(false);
  const [isScreenRecording, setIsScreenRecording] = useState<boolean>(false);
  const [isScreenSharing, setIsScreenSharing] = useState<boolean>(false);

  const [openLeaveModal, setOpenLeaveModal] = useState<boolean>(false);
  const handleLeaveRoom = () => {
    setOpenLeaveModal(!openLeaveModal); // Toggle the leave room modal visibility
  };

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const handleOpenMenu = () => {
    setOpenMenu(!openMenu); // Toggle the menu visibility
  };

  useEffect(() => {
    // Check if the room is full (more than one peer)
    if (peerList.length + 1 < 2) {
      setIsRoomFull(true); // Set room as full if the peer count is less than 2
    } else {
      setIsRoomFull(false); // Set room as not full otherwise
    }
  }, [peerList]); // Runs whenever peerList changes


  return (
    <>
      {peerList.length + 1 < 5 ? (
        <RoomContext.Provider value={roomContextValue}>
          <Page
            fromChat={true}
            isScreenRecording={isScreenRecording}
            title={'Chat room'}
            recordingAlertLog={recordingAlertLog}
          >
            <Box
              className="Room"
              sx={{
                height: '100%',
                flexGrow: '1',
                overflow: 'auto',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  height: 'calc(100%-00px)',
                  width: '100%',
                  overflow: 'auto',
                  margintop: '10px',
                }}
              >
                <RoomVideoDisplay
                  userId={userId}
                  isSpeakingToRoom={isSpeakingToRoom}
                  peerConnectionTypes={peerConnectionTypes}
                />
              </Box>

              <Footer>
                <div
                  className={'right'}
                  style={{
                    marginLeft: isDesktop() ? '0' : '10px',
                    filter: `blur(${openMenu ? '20px' : ''})`,
                  }}
                >
                  <RoomAudioControls
                    peerRoom={peerRoom}
                    setSpeakingToRoom={isSpeakingToRoom =>
                      setIsSpeakingToRoom(isSpeakingToRoom)
                    }
                  />
                  <RoomVideoControls
                    peerRoom={peerRoom}
                    isScreenSharing={isScreenSharing}
                    showAlert={showAlert}
                  />
                </div>
                {isDesktop() ? (
                  <>
                    <div className={'center'}>
                      <ScreenRecorderControls
                        peerRoom={peerRoom}
                        isSpeakingToRoom={isSpeakingToRoom}
                        onInlineMediaUpload={handleInlineMediaUpload}
                        showAlert={showAlert}
                        onMessageSubmit={handleRecordingAlertSubmit}
                        isScreenRecording={isScreenRecording}
                        setIsScreenRecording={isScreenRecording =>
                          setIsScreenRecording(isScreenRecording)
                        }
                        recordingAlertLog={recordingAlertLog}
                      />
                      <RoomScreenShareControls
                        peerRoom={peerRoom}
                        setIsScreenSharing={isScreenSharing =>
                          setIsScreenSharing(isScreenSharing)
                        }
                      />
                      <RoomFileUploadControls
                        onInlineMediaUpload={handleInlineMediaUpload}
                        peerRoom={peerRoom}
                      />
                    </div>
                    <div className={'left'}>
                      <SharePasswordControls
                        roomId={roomId}
                        isRoomFull={isRoomFull}
                        showAlert={showAlert}
                        password={password}
                      />
                      <ParticipantsControls
                        roomId={roomId}
                        audioState={audioState}
                        connectionTestResults={connectionTestResults}
                        peerAudios={peerAudios}
                        peerConnectionTypes={peerConnectionTypes}
                        peerList={peerList}
                        userId={userId}
                        users={users}
                        isRoomFull={isRoomFull}
                        usersLoading={usersLoading}
                      />
                      <RoomShowMessagesControls
                        isMessageSending={isMessageSending}
                        messageLog={messageLog}
                        onMessageChange={handleMessageChange}
                        onMessageSubmit={handleMessageSubmit}
                        userId={userId}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className={'left'}
                    style={{
                      alignSelf: 'center',
                      marginLeft: '20px',
                      marginRight: '10px',
                      filter: `blur(${openMenu ? '20px' : ''})`,
                    }}
                  >
                    <CButton
                      startIconSvg={<Leave />}
                      onClick={handleLeaveRoom}
                      background={'red90'}
                      size={'s'}
                      backgroundHover={'red90'}
                    >
                      Leave call
                    </CButton>
                  </div>
                )}
              </Footer>

              {!isDesktop() && (
                <FooterMenu>
                  <div className={'line'} onClick={handleOpenMenu}>
                    <div className={'green'}></div>
                  </div>
                  {openMenu && (
                    <>
                      <div className={'boxMobile'}>
                        <div className={'flex'}>
                          <RoomFileUploadControls
                            onInlineMediaUpload={handleInlineMediaUpload}
                            peerRoom={peerRoom}
                          />
                        </div>
                        <div className={'flex'}>
                          <ParticipantsControls
                            roomId={roomId}
                            audioState={audioState}
                            connectionTestResults={connectionTestResults}
                            peerAudios={peerAudios}
                            peerConnectionTypes={peerConnectionTypes}
                            peerList={peerList}
                            userId={userId}
                            users={users}
                            isRoomFull={isRoomFull}
                            usersLoading={usersLoading}
                          />
                        </div>
                      </div>
                      <div className={'boxMobile'}>
                        <div className={'flex1'}>
                          <SharePasswordControls
                            roomId={roomId}
                            isRoomFull={isRoomFull}
                            showAlert={showAlert}
                            password={password}
                          />
                        </div>
                        <div className={'flex2'}>
                          <RoomShowMessagesControls
                            isMessageSending={isMessageSending}
                            messageLog={messageLog}
                            onMessageChange={handleMessageChange}
                            onMessageSubmit={handleMessageSubmit}
                            userId={userId}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </FooterMenu>
              )}
            </Box>
          </Page>

          <CModal
            open={openLeaveModal}
            onClose={() => {
              setOpenLeaveModal(false)
            }}
            title=""
            width={'300'}
            background={'navy90'}
          >
            <LeaveCall>
              <div className={'icon'}>
                <Warning />
              </div>
              <h3>Warning</h3>
              <p>
                With Leaving this call all shared data in the call will be lost
                to you! Do you want to proceed?
              </p>

              <div style={{ marginBottom: '24px' }} />
              <CButton
                onClick={() => {
                  setOpenLeaveModal(false)
                  window.opener = null
                  window.open('', '_self')
                  window.close()
                  window.history.go(-1)
                }}
                background={'navy25'}
                backgroundHover={'navy25'}
                fullWidth={true}
                size={'s'}
              >
                Yes,Proceed
              </CButton>
              <div style={{ marginBottom: '10px' }} />
              <CButton
                onClick={() => setOpenLeaveModal(false)}
                background={'red120'}
                backgroundHover={'red120'}
                fullWidth={true}
                size={'s'}
              >
                No, Cancel
              </CButton>
            </LeaveCall>
          </CModal>
        </RoomContext.Provider>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          You’ve reached the limit of 4 participants for this video call. No
          additional participants can be added
          <Link to="/">
            <button style={{ width: '160px', height: '40px' }}>
              Back Home
            </button>
          </Link>
        </div>
      )}
    </>
  )
}
