import React from 'react'
import styled from 'styled-components'
import { PlatformProfileModel } from '../../../models/platform/platform-profile.model.'

const PlatformDataStyle = styled.div<{ textcolor: string }>`
    display: flex;
    position: relative;
    color: #F8FCFB;
    left: -137px;
    top: 60px;
margin-top: 10px;
    > p {
        font-size: 14px;
        font-weight: 400;
        font-family: Inter;
        color: #FFFFFFD9;
        line-height: 22px;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    > .icon {
        position: relative;
        left: 10px;
        cursor: pointer;
    }
`


export interface Props {
  platform: PlatformProfileModel
}

/* 
Displays the platform name associated with a startup if the platform exists. 
Renders nothing if the platform is undefined or null.
*/
export function PlatformData(props: Props) {
  const { platform } = props

  return (
    <>
      <PlatformDataStyle textcolor={'white100'}>
            <p>48 Followers</p>
            <p>{platform.companySize} Team member</p>
            <p>5 Startup</p>
      </PlatformDataStyle>
    </>
  )
}
