import { useEffect, useState } from "react";
import { CModalTwo } from "../../mui/ModalTwo";
import { LoginChildSec } from "../../newStructures/LoginChild.style";
import { CButton } from "../../mui";
import { CButtonTwo } from "../../mui/ButtonTwo";
import { ProfileModel } from "../../../models/profile.model";
import { useDispatch } from "react-redux";
import { useGlobalContext, useGlobalStyleContext } from '../../../contexts'
import { set } from "../../../redux/slices/user";
import { useNavigate } from "react-router-dom";
import { isDesktop } from "../../../utils/detect-screen";
import { CSvgButton } from "../../mui/SvgButton";
import { ReactComponent as CloseLogin } from '../../../assets/svg/close-login.svg'


interface Props {
  color?: string
  textColor?: string
  size?: 's' | 'm' | 'l'
  setLoginState: (loginState: boolean) => void
  tempUser: ProfileModel
  onClose?: () => void
}

export function LastWelcomePart(props: Props) {
  const { color, textColor, size, setLoginState, tempUser,onClose } = props;
  const { makeAlert } = useGlobalStyleContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const finalStartExplore = () => {
    if (tempUser) {
      dispatch(set(tempUser));
      makeAlert("success", "Register account successfully done");
    }
    setLoginState(false);
  };
  const finalToProfile = () => {
    if (tempUser) {
      dispatch(set(tempUser));
      makeAlert("success", "Register account successfully done");
    }
    setLoginState(false);
    navigate("/profile");
  };

  return isDesktop() ? (
    <>
      {/* <CModalTwo
        open={loginState}
        onClose={closeLoginModal}
        title="login modal"
        width="540"
      > */}
      <LoginChildSec>
        <CSvgButton
          icon={<CloseLogin />}
          disabled={false}
          onClick={onClose}
          customColor={'white100'}
          backgroundColorHover={'white'}
          backgroundColor="#140e26"
          style={{
            position: 'absolute',
            right: '0px',
            top: '0px',
            marginTop: '15px',
            marginRight: '15px',
          }}
        />
        <div className="top-heading">
          <h3 className="title">Welcome To Personia!</h3>
          <div className="subtitle">
            Your journey in Personia begins now. Let’s get started!
          </div>
        </div>
        <div className="loading preWelcome">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              width: '100%',
              gap: '1rem',
            }}
          >
            <CButtonTwo
              fullWidth
              size={'s'}
              background={color}
              backgroundHover={color}
              color={textColor}
              loadingColor={textColor}
              onClick={finalToProfile}
              height={'48px'}
              fontSize="14px"
              maxwidth="238px"
              fontWeight={500}
            >
              Complete Your Profile
            </CButtonTwo>
            <CButtonTwo
              fullWidth
              size={'s'}
              background={'transparent'}
              backgroundHover={'white'}
              color={'#39DBB2'}
              loadingColor={textColor}
              onClick={finalStartExplore}
              height={'48px'}
              fontSize="14px"
              maxwidth="238px"
              border="#39DBB2"
              fontWeight={500}
            >
              Start Exploring
            </CButtonTwo>
          </div>
        </div>
      </LoginChildSec>
      {/* </CModalTwo> */}
    </>
  ) : (
    <>
      {/* <CModalTwo
        open={loginState}
        onClose={closeLoginModal}
        title="login modal"
        width="540"
      > */}
      <LoginChildSec>
        <div className="top-heading">
          <h3 className="title">Welcome To Personia!</h3>
          <div className="subtitle">
            Your journey in Personia begins now. Let’s get started!
          </div>
        </div>
        <div className="loading preWelcome">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              gap: '2rem',
            }}
          >
            <CButtonTwo
              fullWidth
              size={'s'}
              background={color}
              backgroundHover={color}
              color={textColor}
              loadingColor={textColor}
              onClick={finalStartExplore}
              height={'40px'}
              fontSize="14px"
            >
              Start Exploring
            </CButtonTwo>
            <CButtonTwo
              fullWidth
              size={'s'}
              background={color}
              backgroundHover={color}
              color={textColor}
              loadingColor={textColor}
              onClick={finalToProfile}
              height={'40px'}
              fontSize="14px"
            >
              Complete Your Profile
            </CButtonTwo>
          </div>
        </div>
      </LoginChildSec>
      {/* </CModalTwo> */}
    </>
  )
}
