import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PlatformProfileModel } from '../../../models/platform/platform-profile.model.'

const SloganStyle = styled.div<{ textcolor: string }>`
  display: flex;
  position: relative;
  left: 32px;
  top: -10px;

  > p {
    font-size: 16px;
    font-weight: 400;
    font-family: Inter;
    color: ${({ theme, textcolor }) => theme[textcolor]};
    line-height: 24px;
    margin-bottom: 20px;
  }

  > .icon {
    position: relative;
    left: 10px;
    cursor: pointer;
  }
`

/*
  Renders the slogan of a platform profile, 
  utilizing a styled component for customizable text color. 
  Displays the slogan from the startup data if available.
*/
export interface Props {
  platform: PlatformProfileModel,
}

export function Slogan(props: Props) {
  const { platform } = props

  return (
    <>
      <SloganStyle textcolor={'white100'}>
        <p>{platform?.slogan}</p>
        {/* <p>Slogan of the Platform </p> */}
      </SloganStyle>
    </>
  )
}
