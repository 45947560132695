import React from 'react'
import { LoginChildSec } from '../../newStructures/LoginChild.style'
import { CLoader } from '../../custom'
import { isDesktop } from '../../../utils/detect-screen'
import { CSvgButton } from '../../mui/SvgButton'
import { ReactComponent as CloseLogin } from '../../../assets/svg/close-login.svg'

type Props = {
  onClose?: () => void
}

export const Loading = ({onClose}: Props) => {
  return isDesktop() ? (
    <LoginChildSec>
      <CSvgButton
        icon={<CloseLogin />}
        disabled={false}
        onClick={onClose}
        customColor={'white100'}
        backgroundColorHover={'white'}
        backgroundColor="#140e26"
        style={{
          position: 'absolute',
          right: '0px',
          top: '0px',
          marginTop: '15px',
          marginRight: '15px',
        }}
      />
      <div className="top-heading">
        <h3 className="title">Loading</h3>
        <div className="subtitle">It takes a few seconds, please wait.</div>
      </div>
      <div className="loading">
        <CLoader
          width={50}
          height={100}
          central={true}
          margin="0"
          color="green100"
        />
      </div>
    </LoginChildSec>
  ) : (
    <LoginChildSec>
      <div className="top-heading">
        <h3 className="title">Loading</h3>
        <div className="subtitle">It takes a few seconds, please wait.</div>
      </div>
      <div className="loading">
        <CLoader
          width={50}
          height={100}
          central={true}
          margin="0"
          color="green100"
        />
      </div>
    </LoginChildSec>
  )
}
