import React from "react";
import { LoginChildSec } from "../../newStructures/LoginChild.style";
import { CButtonTwo } from "../../mui/ButtonTwo";
import { CSvgButton } from "../../mui/SvgButton";
import { ReactComponent as CloseLogin } from '../../../assets/svg/close-login.svg'
import { isDesktop } from "../../../utils/detect-screen";


type Props = {
  loading: boolean
  setLoginStep: (
    step: 'welcome' | 'signup-question' | 'signup' | 'none' | 'walletIframe'
  ) => void
  onClose: () => void
  setNewLogin: (newLogin: boolean) => void
}

export const SignupQuestion = ({
  loading,
  setLoginStep,
  setNewLogin,
  onClose,
}: Props) => {
  return isDesktop() ? (
    <LoginChildSec>
      <CSvgButton
        icon={<CloseLogin />}
        disabled={false}
        onClick={onClose}
        customColor={'white100'}
        backgroundColorHover={'white'}
        backgroundColor="#140e26"
        style={{
          position: 'absolute',
          right: '0px',
          top: '0px',
          marginTop: '15px',
          marginRight: '15px',
        }}
      />
      <div className="top-heading">
        <h3 className="title">Join Our Community</h3>
        <div className="subtitle">
          Welcome! You don’t have an account with this wallet. Let's get you set
          up.
        </div>
      </div>
      <div className="items-box-row">
        <CButtonTwo
          color={'black'}
          background={'#39DBB2'}
          hoverColor={'black'}
          backgroundHover={'#39DBB2'}
          variant={'filled'}
          fullWidth={true}
          type={'button'}
          size="s"
          loading={false}
          fontWeight={600}
          fontSize={'12px'}
          onClick={() => setLoginStep('signup')}
          height={'40px'}
          maxwidth={'238px'}
          border={'transparent'}
        >
          Continue with current wallet
        </CButtonTwo>
        <CButtonTwo
          color={'#39DBB2'}
          background={'#140e26'}
          backgroundHover={'transparent'}
          variant={'filled'}
          fullWidth={true}
          type={'button'}
          size="s"
          fontWeight={600}
          fontSize={'12px'}
          maxwidth={'238px'}
          loading={false}
          onClick={() => setLoginStep('walletIframe')}
          height={'40px'}
          border={'#39DBB2'}
        >
          Change Wallet
        </CButtonTwo>
      </div>
      {/* <div className="link-box">
        Try another wallet?{' '}
        <span
          onClick={() => {
            setNewLogin(true)
            localStorage.removeItem('token')
          }}
        >
          Login
        </span>
      </div> */}
    </LoginChildSec>
  ) : (
    <LoginChildSec>
      <div className="top-heading">
        <h3 className="title">Sign up!</h3>
        <div className="subtitle">
          You don’t have an account with this wallet on Personia.
        </div>
      </div>
      <div className="items-box-row">
        <CButtonTwo
          color={'#39DBB2'}
          background={'#140E26'}
          hoverColor={'#fff'}
          backgroundHover={'transparent'}
          variant={'filled'}
          fullWidth={true}
          type={'button'}
          size="s"
          loading={false}
          border={'#39DBB2'}
          fontWeight={600}
          fontSize={'12px'}
          onClick={onClose}
          height={'40px'}
          maxwidth={'296px'}
        >
          Cancel
        </CButtonTwo>
        <CButtonTwo
          color={'#140E26'}
          background={'#39DBB2'}
          backgroundHover={'#2aaa8a'}
          variant={'filled'}
          fullWidth={true}
          type={'button'}
          size="s"
          fontWeight={600}
          fontSize={'12px'}
          maxwidth={'296px'}
          loading={false}
          onClick={() => setLoginStep('signup')}
          height={'40px'}
        >
          Sign up with current wallet
        </CButtonTwo>
      </div>
      {/* <div className="link-box">
        Try another wallet?{' '}
        <span
          onClick={() => {
            setNewLogin(true)
            localStorage.removeItem('token')
          }}
        >
          Login
        </span>
      </div> */}
    </LoginChildSec>
  )

};
