import { useState, useEffect } from 'react'
import { CSvgButton } from '../../mui/SvgButton'
import { ReactComponent as VolumeHigh } from '../../../assets/svg/volume-high.svg'
import { ReactComponent as VolumeSlash } from '../../../assets/svg/volume-slash.svg'

interface AudioVolumeProps {
  audioEl: HTMLAudioElement
}

export const AudioVolume = ({ audioEl }: AudioVolumeProps) => {
  const [audioVolume, setAudioVolume] = useState(audioEl.volume)

  useEffect(() => {
    // Whenever 'audioEl' or 'audioVolume' changes, update the volume of the audio element
    audioEl.volume = audioVolume;
  }, [audioEl, audioVolume]);

  // Function to toggle audio volume between mute (0) and full volume (1)
  const handleIconClick = () => {
    if (audioVolume === 0) {
      // If the volume is currently muted, set it to full volume (1)
      setAudioVolume(1);
    } else {
      // If the volume is currently full, mute it (set to 0)
      setAudioVolume(0);
    }
  };


  return (
      <CSvgButton icon={audioVolume === 0 ? <VolumeSlash /> : <VolumeHigh />}
                  backgroundColorHover={'white60'}
                  customColor={'white100'}
                  backgroundColor={'white60'}
                  onClick={handleIconClick}/>
  )
}
