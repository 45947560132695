import { useAppSelector } from '../../redux/hooks'
import React, { ReactElement, useEffect, useState } from 'react'
import { Page } from '../../components/structure'
import styled from 'styled-components'
import '../../assets/editor.css'
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { CButton, CSkeleton, CTab, CTabs } from '../../components/mui'
import { Banner, Logo, Name, Slogan } from '../../components/platform/header'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model.'
import { PlatformData } from '../../components/platform/header/platformData'
import { isDesktop, isTablet } from '../../utils/detect-screen'
import { ProfileBox } from '../../components/profile'
import { findSinglePlatform } from '../../apis/platform.apis'

const Box = styled.div`
  > .row-1 {
    width: 100%;
    height: 315px;
    border-radius: 8px;
    border: 0.5px solid #888282;
    top: 60px;

    > .flex {
      display: flex;
      flex-wrap: wrap;
      position: relative;

      > .wrap {
        width: 100%;
      }

      > .right {
        position: absolute;
        right: 20px;
        top: 20px;
        float: right;
        vertical-align: center;
      }
    }
  }

  > .row-2 {
    > .body {
      padding: 20px;
      background: ${props => props.theme.navy80};

      > .text {
        position: relative;

        > div:last-child {
          position: relative;
        }

        p,
        span {
          color: ${props => props.theme.white100};
        }

        > h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ul,
        li,
        ol {
          color: ${props => props.theme.white100};
        }

        > a {
          color: ${props => props.theme.green100};
        }

        > img {
          margin-top: 20px;
        }

        .title {
          font-size: 38px;
          font-weight: 600;
        }

        .allText {
          font-size: 14px;
          line-height: 22px;
          margin-top: 15px;
          color: #ffffff;
        }

        .detail {
          font-size: 14px;
          font-weight: 500;
          margin-top: 15px;
        }

        .detail2 {
          font-size: 14px;
          font-weight: 500;
          margin-top: 8px;
          color: #1677ff;
        }

        .detail3 {
          font-size: 14px;
          font-weight: 500;
          margin-top: 8px;
          color: #ffffffa6;
        }
      }
    }
  }
`

const TabBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  position: sticky;
  top: 0;
  z-index: 20;
  background: ${props => props.theme.navy80};
  border-radius: 16px;

  > span {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.black80};
  }
`

export function PlatformDetailPage(): ReactElement {
  
  /* 
  Scrolls the window to the top-left corner smoothly when the component mounts. 
  This effect runs only once due to the empty dependency array. 
  */
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const { id } = useParams()
  const [platform, setPlatform] = useState<PlatformProfileModel>()
  const [platformLoading, setPlatformLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [tab, setTab] = useState<'posts' | 'articles' | 'journey' | 'about'>(
    'journey'
  )

  /* Fetches platform data when the component mounts, updating the platform state on success. */
  useEffect(() => {
    findSinglePlatform(id)
      .then(result => {
        setPlatform(result.data.platform)
      })
      .catch(error => {})
  }, [])

  /* 
  Handles the change of the view type by updating the tab state 
  with the newly selected value from the event. 
  */
  const handleChangeViewType = (event: any, newValue: any): void => {
    setTab(newValue)
  }

  return (
    <Page
      title="Platform"
      sidebar2={
        <>
          <div className={'back'}>
            <CButton
              size={'s'}
              background={'navy100'}
              backgroundHover={'navy100'}
              backgroundDisabled={'navy100'}
              color={'white100'}
              onClick={() => navigate(-1)}
              startIcon={'keyboard_arrow_left'}
            >
              <span style={{ marginLeft: '5px' }}>Back</span>
            </CButton>
          </div>
        </>
      }
    >
      <Box>
        {platformLoading ? (
          <>
            <div className="row-1">
              <CSkeleton width="100%" borderradius="8px" />
              <div className="flex">
                <CSkeleton
                  width="162px"
                  height="162px"
                  borderradius="1000px"
                  margintop="-80px"
                  marginLeft="16px"
                />
                <div>
                  <CSkeleton
                    width={200}
                    height={10}
                    marginbottom="5px"
                    marginLeft="16px"
                    margintop="20px"
                  />
                  <CSkeleton
                    width={200}
                    height={10}
                    marginbottom="5px"
                    marginLeft="16px"
                  />
                </div>
              </div>
            </div>
            <div className="row-2">
              <TabBox>
                <CTabs
                  value={tab}
                  key={1}
                  $padding="20px"
                  $background="navy60"
                  $activeBG="navy60"
                  onChange={handleChangeViewType}
                >
                  <CTab
                    label="Journey"
                    id="view-tab-journey"
                    aria-controls="view-tabpanel-journey"
                    value="journey"
                    disableTouchRipple
                    $fullWidth
                  />
                  <CTab
                    label="about"
                    id="view-tab-about"
                    aria-controls="view-tabpanel-about"
                    value="about"
                    disableTouchRipple
                    $fullWidth
                  />
                </CTabs>
              </TabBox>
              <div className="body">
                {tab === 'journey' && (
                  <CSkeleton
                    width="90%"
                    height={10}
                    marginbottom="20px"
                    marginLeft="16px"
                    margintop="20px"
                    marginRight="16px"
                  />
                )}
                {tab === 'about' && (
                  <CSkeleton
                    width="90%"
                    height={10}
                    marginbottom="20px"
                    marginLeft="16px"
                    margintop="20px"
                    marginRight="16px"
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          platform && (
            <>
              <div className="row-1">
                <Banner platform={platform} height="202" />
                <div className="flex">
                  <Logo platform={platform} />
                  <div>
                    <Name platform={platform} />
                    <Slogan platform={platform} />
                  </div>
                  <div>
                    <PlatformData platform={platform} />
                  </div>
                </div>
              </div>
              <div className="row-2">
                <TabBox>
                  <CTabs
                    value={tab}
                    key={1}
                    $padding="20px"
                    $background="navy60"
                    $activeBG="navy60"
                    onChange={handleChangeViewType}
                  >
                    <CTab
                      label="Journey"
                      id="view-tab-journey"
                      aria-controls="view-tabpanel-journey"
                      value="journey"
                      disableTouchRipple
                      $fullWidth
                    />
                    <CTab
                      label="about"
                      id="view-tab-about"
                      aria-controls="view-tabpanel-about"
                      value="about"
                      disableTouchRipple
                      $fullWidth
                    />
                  </CTabs>
                </TabBox>
                <div className="body">
                  {tab === 'journey' && (
                    <div className="text">
                      <div className="editor">
                        <div
                          className="ql-container ql-snow"
                          style={{ height: 'auto' }}
                        >
                          <div
                            className="ql-editor"
                            dangerouslySetInnerHTML={{
                              __html: decodeURIComponent(
                                platform.projectMission
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
                  {tab === 'about' && (
                    <div className="text">
                      <div className="editor">
                        <h2 className="title">Overview</h2>
                        <p className="allText">{platform.about}</p>
                        <p className="allText">{platform.about}</p>
                        <p className="allText">{platform.about}</p>
                        <div>
                          <p className="detail">Website</p>
                          <a href="https://embodia.io/" className="detail2">
                            {platform.website}
                          </a>
                        </div>
                        <div>
                            <p className="detail">Phone</p>
                            {isDesktop() ? (
                              <p className="detail3">{platform.phoneNumber}</p>
                            ) : (
                              <a href={`tel:${platform.phoneNumber}`} className="detail2">
                                {platform.phoneNumber}
                              </a>
                            )}

                        </div>
                        <div>
                          <p className="detail">Address</p>
                          <p className="detail3">{platform.address}</p>
                        </div>
                        <div>
                          <p className="detail">Company size</p>
                          <p className="detail3">{platform.companySize}</p>
                        </div>
                        <div>
                          <p className="detail">Founded</p>
                          <p className="detail3">
                            {new Date(
                              platform.fundingStartDate
                            ).toLocaleDateString()}
                          </p>
                        </div>
                        <div>
                          <p className="detail">Specialties</p>
                          <p className="detail3">{platform.specialties}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )
        )}
      </Box>
    </Page>
  )
}
