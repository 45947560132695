import React, { createContext, useState, ReactNode } from 'react'

interface NotificationContextType {
  notifications: string[]
  addNotification: (message: string) => void
  setNotifications: React.Dispatch<React.SetStateAction<string[]>>;
}

export const NotificationContext = createContext<
  NotificationContextType | undefined
>(undefined)

interface NotificationProviderProps {
  children: ReactNode
}

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [notifications, setNotifications] = useState<string[]>([])

  const addNotification = (message: string) => {
    setNotifications(prevNotifications => [...prevNotifications, message])
  }

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, setNotifications }}>
      {children}
    </NotificationContext.Provider>
  )
}
