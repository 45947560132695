import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Banner, Logo, Name, PlatformName, Slogan } from './header'
import { Link } from 'react-router-dom'
import { CSkeleton } from '../mui'
import { StartupProfileModel } from '../../models/startup/startup-profile.model'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model.'
import { isDesktop, isMobile } from '../../utils/detect-screen'

const breakpoints = {
  mobile: '320px',
  tablet: '1130px',
  desktop: '1024px',
}

const Box = styled.div`
  background: ${props => props.theme.navy80};
  margin-bottom: 15px;
  border-radius: 8px;
  display: block;
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.white30};

  > a {
    text-decoration: none;

    > .row-1 {
      width: 100%;
      height: 315px;
      border-radius: 16px;

      @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
        .flex {
          width: 100%;
          flex-wrap: wrap;
        }
      }
      > .flex {
        display: flex;
        position: relative;
        > .flex-content {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
            justify-content: center;
          }
        }

        > .right {
          position: absolute;
          right: 20px;
          top: 20px;
          float: right;
          vertical-align: center;
        }
      }
    }

    > .row-2 {
      > p {
        font-size: 16px;
        color: ${props => props.theme.white100};
        line-height: 24px;
        display: block;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: pre-line;
        overflow: hidden;
        margin-bottom: 20px;
        text-align: justify;
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }
`

interface Props {
  startup?: StartupProfileModel
  platform?: PlatformProfileModel
  loading?: boolean
}

/* 
Renders a startup box that displays either loading skeletons or the startup details, including a banner, logo, name, slogan, and platform information.
Links to the startup's detailed page when data is available.
*/
export function StartupBox(props: Props): ReactElement {
  return (
    <Box>
      {props.loading ? (
        <Link to={'#'}>
          <div className={'row-1'}>
            <CSkeleton width={'100%'} borderradius={'8px'} />
            <div className={'flex'}>
              <CSkeleton
                width={'162px'}
                height={'162px'}
                borderradius={'1000px'}
                margintop={'-80px'}
                marginLeft={'16px'}
              />
              <div>
                <CSkeleton
                  width={200}
                  height={10}
                  marginbottom={'5px'}
                  marginLeft={'16px'}
                  margintop={'20px'}
                />
                <CSkeleton
                  width={200}
                  height={10}
                  marginbottom={'5px'}
                  marginLeft={'16px'}
                />
              </div>
            </div>
          </div>
          <div className={'row-2'}>
            <CSkeleton
              width={'90%'}
              height={10}
              marginbottom={'20px'}
              marginLeft={'16px'}
              margintop={'20px'}
              marginRight={'16px'}
            />
          </div>
        </Link>
      ) : (
        <Link to={'/startups/get/' + props.startup.id}>
          <div className={'row-1'}>
            <Banner startup={props.startup} />
            <div className={'flex'}>
              <Logo startup={props.startup} />
              <div className='flex-content' style={isDesktop() ? null : { width: '100%' }}>
                <Name startup={props.startup} />
                <Slogan startup={props.startup} />
              </div>
            </div>
          </div>
          <div className={'row-2'} style={isMobile() ? {marginTop: '20px'} : null}>
            <p
              dangerouslySetInnerHTML={{
                __html: decodeURIComponent(props.startup.projectMission),
              }}
            ></p>
          </div>
        </Link>
      )}
    </Box>
  )
}
