import { httpApi } from './http.api'
import { StartupModel } from '../models/startup/startup.model'
import { PlatformProfileModel } from '../models/platform/platform-profile.model.'

export interface PageResponse {
  success: boolean
  message: string
  data: {
    platforms: PlatformProfileModel[]
    cursor: string
  }
}

export interface PageResponseSingle {
  success: boolean
  message: string
  data: {
    platform: PlatformProfileModel
  }
}

export interface platformRequests {
  numberPerPage: number
  cursor: string
  search?: {
    q?: string
    profileIDs?: string[]
  }
}

export const findAllPlatforms = (
  req: platformRequests
): Promise<PageResponse> =>
  httpApi
    .get<PageResponse>(
      `platforms?profiles=${req.search.profileIDs}&q=${req.search.q}&cursor=${req.cursor}&perPage=${req.numberPerPage}`
    )
    .then(({ data }) => data)

export const findSinglePlatform = (id: string): Promise<PageResponseSingle> =>
  httpApi.get<PageResponseSingle>(`platforms/${id}`).then(({ data }) => data)
