import styled from 'styled-components'
import React, { ReactElement, useState } from 'react'
import { CIconButton, CTextField } from '../mui'
import { FieldErrors } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { breakpoints } from '../../config/global-styles'
import { experienceModel } from '../../models/experience.model'
import { educationModel } from '../../models/education.model'

const Flex = styled.div<{ marginRight: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  > *:first-child {
    flex-grow: 1;
    margin-right: ${({ marginRight }) => marginRight};
  }
`

const Length = styled.div`
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.gray50};
  display: block;
  text-decoration: none;
  margin-top: -40px;
  margin-right: 25px;
`

type item = {
  title?: string
  city?: string
  id?: string
  company?: string
  school?: string
  startDate?: Date | undefined
  endDate?: Date | undefined
  description?: string
  isDeleted?: boolean
}

interface Props {
  name: string
  item: item
  index: number
  control: any
  errors: FieldErrors
  data: {
    name: string
    placeholder?: string
  }[]
  isSubmitting?: boolean
  append: (item: experienceModel | educationModel) => void
}

export function ProfileField({ name, item, isSubmitting, append }: Props) {
  const [length, setLength] = useState<number>(item.description?.length ?? 0)

  const [title, setTitle] = useState<string>('')
  const [titleError, setTitleError] = useState<string>('')

  const [companyName, setCompanyName] = useState<string>('')
  const [companyNameError, setCompanyNameError] = useState<string>('')

  const [schoolName, setSchoolName] = useState<string>('')
  const [schoolNameError, setSchoolNameError] = useState<string>('')

  const [city, setCity] = useState<string>('')
  const [cityError, setCityError] = useState<string>('')

  const [startDate, setStartDate] = useState<undefined | string>('')
  const [startDateError, setStartDateError] = useState<string>('')

  const [endDate, setEndDate] = useState<undefined | string>('')
  const [endDateError, setEndDateError] = useState<string>('')

  const [description, setDescription] = useState<string>('')
  const [descriptionError, setDescriptionError] = useState<string>('')

  const addItem = () => {
    setTitleError('')
    setCompanyNameError('')
    setSchoolNameError('')
    setCityError('')
    setEndDateError('')
    setDescriptionError('')

    var canGo = true
    if (item.title === '') {
      setTitleError(
        'Please ensure all required fields, indicated with (*), are filled before submitting.'
      )
      canGo = false
    } else if (item.title.length > 225) {
      setTitleError('Please edit to 225 characters or fewer')
      canGo = false
    } else if (item.title.length <= 2) {
      setTitleError('Please provide at least 3 characters')
      canGo = false
    }
    if (name === 'experiences') {
      if (item.company === '') {
        setCompanyNameError(
          'Please ensure all required fields, indicated with (*), are filled before submitting.'
        )
        canGo = false
      } else if (item.company.length > 225) {
        setCompanyNameError('Please edit to 225 characters or fewer')
        canGo = false
      } else if (item.company.length <= 2) {
        setCompanyNameError('Please provide at least 3 characters')
        canGo = false
      }
    } else {
      if (item.school === '') {
        setSchoolNameError(
          'Please ensure all required fields, indicated with (*), are filled before submitting.'
        )
        canGo = false
      } else if (item.school.length > 225) {
        setSchoolNameError('Please edit to 225 characters or fewer')
        canGo = false
      } else if (item.school.length <= 2) {
        setSchoolNameError('Please provide at least 3 characters')
        canGo = false
      }
    }

    if (item.city === '') {
      setCityError(
        'Please ensure all required fields, indicated with (*), are filled before submitting.'
      )
      canGo = false
    } else if (item.city.length > 225) {
      setCityError('Please edit to 225 characters or fewer')
      canGo = false
    } else if (item.city.length <= 2) {
      setCityError('Please provide at least 3 characters')
      canGo = false
    }

    if (!item.startDate) {
      setStartDateError(
        'Please ensure all required fields, indicated with (*), are filled before submitting.'
      )
      canGo = false
    }

    if (item.startDate && item.endDate) {
      if (
        new Date(item.startDate).getTime() >= new Date(item.endDate).getTime()
      ) {
        setEndDateError(
          'The start date must be before the end date/The start date and end date cannot be the same.'
        )
        canGo = false
      }
    }

    if (item.description.length > 1000) {
      setDescriptionError('Please edit to 1000 characters or fewer')
      canGo = false
    }

    if (canGo) {
      append(item)
      // Reset item and form fields to their default values
      item.title = ''
      item.school = ''
      item.company = ''
      item.city = ''
      item.startDate = undefined
      item.endDate = undefined
      item.description = ''

      setTitle('')
      setTitleError('')

      setCompanyName('')
      setCompanyNameError('')

      setCity('')
      setCityError('')

      setSchoolName('')
      setSchoolNameError('')

      setStartDate('')

      setEndDate('')

      setDescription('')
      setDescriptionError('')

      setLength(0)
    }
  }

  return (
    <div>
      <Flex marginRight={'15px'}>
        {name === 'experiences' ? (
          <div style={{ flexDirection: 'column' }}>
            <CTextField
              disabled={isSubmitting}
              label={'Title *'}
              hasError={!!titleError}
              helperText={titleError}
              // defaultValue={item.title}
              value={title}
              placeholder={'Ex: Retail Sales Manager'}
              onChange={event => {
                setTitleError('')
                setTitle(event.target.value)
                if (event.target.value === '') {
                  setTitleError(
                    'Please ensure all required fields, indicated with (*), are filled before submitting.'
                  )
                } else if (event.target.value.length > 225) {
                  setTitleError('IPlease edit to 225 characters or fewer')
                } else if (event.target.value.length <= 2) {
                  setTitleError('Please provide at least 3 characters')
                } else {
                  item.title = event.target.value
                }
              }}
            />
          </div>
        ) : (
          <div style={{ flexDirection: 'column' }}>
            <CTextField
              disabled={isSubmitting}
              label={'Field of Study * '}
              // defaultValue={item.title}
              hasError={!!titleError}
              helperText={titleError}
              placeholder={'EX: Computer software engineering'}
              onChange={event => {
                setTitleError('')
                setTitle(event.target.value)
                if (event.target.value === '') {
                  setTitleError(
                    'Please ensure all required fields, indicated with (*), are filled before submitting.'
                  )
                } else if (event.target.value.length > 225) {
                  setTitleError('Please edit to 225 characters or fewer')
                } else if (event.target.value.length <= 2) {
                  setTitleError('Please provide at least 3 characters')
                } else {
                  item.title = event.target.value
                }
              }}
            />
          </div>
        )}
        <div style={{marginTop: titleError ? '-15px' : ''}}>
          <CIconButton
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={() => addItem()}
            icon="add"
          />
        </div>

      </Flex>
      <Flex marginRight={'15px'}>
        {name === 'experiences' ? (
          <div style={{ flexDirection: 'column', flex: 1, height: (companyNameError || schoolNameError || cityError) ? '100px' : 'auto' }}>
            <CTextField
              disabled={isSubmitting}
              label={'Company Name *'}
              hasError={!!companyNameError}
              helperText={companyNameError}
              // defaultValue={item.company}
              value={companyName}
              onChange={event => {
                setCompanyNameError('')
                setCompanyName(event.target.value)
                if (event.target.value === '') {
                  setCompanyNameError(
                    'Please ensure all required fields, indicated with (*), are filled before submitting.'
                  )
                } else if (event.target.value.length > 225) {
                  setCompanyNameError('Please edit to 225 characters or fewer')
                } else if (event.target.value.length <= 2) {
                  setCompanyNameError('Please provide at least 3 characters')
                } else {
                  item.company = event.target.value
                }
              }}
              placeholder={'Ex: Microsoft'}
            />
          </div>
        ) : (
          <div style={{ flexDirection: 'column', flex: 1 }}>
            <CTextField
              disabled={isSubmitting}
              label={'School Name *'}
              placeholder={'Ex: Boston University'}
              hasError={!!schoolNameError}
              helperText={schoolNameError}
              // defaultValue={item.school}
              value={schoolName}
              onChange={event => {
                setSchoolName('')
                setSchoolNameError('')
                setSchoolName(event.target.value)
                if (event.target.value === '') {
                  setSchoolNameError(
                    'Please ensure all required fields, indicated with (*), are filled before submitting.'
                  )
                } else if (event.target.value.length > 225) {
                  setSchoolNameError('Please edit to 225 characters or fewer')
                } else if (event.target.value.length <= 2) {
                  setSchoolNameError('Please provide at least 3 characters')
                } else {
                  item.school = event.target.value
                }
              }}
            />
          </div>
        )}
        <div style={{ flexDirection: 'column', flex: 1 }}>
          <CTextField
            disabled={isSubmitting}
            label={'City *'}
            placeholder={'Ex: London'}
            hasError={!!cityError}
            helperText={cityError}
            // defaultValue={item.city}
            value={city}
            onChange={event => {
              setCityError('')
              setCity(event.target.value)
              if (event.target.value === '') {
                setCityError(
                  'Please ensure all required fields, indicated with (*), are filled before submitting.'
                )
              } else if (event.target.value.length > 225) {
                setCityError('Please edit to 225 characters or fewer')
              } else if (event.target.value.length <= 2) {
                setCityError('Please provide at least 3 characters')
              } else {
                item.city = event.target.value
              }
            }}
          />
        </div>
      </Flex>
      <Flex marginRight={'15px'}>
        <div className={'datepicker'} style={{height: (startDateError || endDateError) ? '150px' : 'auto' }}>
          <div style={{ flexDirection: 'column'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DemoItem label={'Start date *'}>
                  <DatePicker
                    value={dayjs(startDate) ?? null}
                    // defaultValue={
                    //   item.startDate != null ? dayjs(item.startDate) : null
                    // }
                    className={'datepicker'}
                    onChange={val => {
                      setStartDateError('')
                      setStartDate(
                        val
                          ? `${val?.toDate()?.getFullYear()}-${(
                              val?.toDate()?.getMonth() + 1
                            )
                              ?.toString()
                              ?.padStart(2, '0')}-${val
                              .toDate()
                              ?.getDate()
                              .toString()
                              .padStart(2, '0')}`
                          : undefined
                      )
                      if (!val) {
                        setStartDateError(
                          'Please ensure all required fields, indicated with (*), are filled before submitting.'
                        )
                      } else {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        item.startDate = val
                          ? `${val?.toDate()?.getFullYear()}-${(
                              val?.toDate()?.getMonth() + 1
                            )
                              ?.toString()
                              ?.padStart(2, '0')}-${val
                              .toDate()
                              ?.getDate()
                              .toString()
                              .padStart(2, '0')}`
                          : undefined
                      }
                    }}
                  />
                  {startDateError && (
                    <span className={'error'}>{startDateError}</span>
                  )}
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
        <div className={'datepicker'} style={{height: (startDateError || endDateError) ? '150px' : 'auto' }}>
          <div style={{ flexDirection: 'column' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DemoItem label={'End date (or expected)'}>
                  <DatePicker
                    value={dayjs(endDate)}
                    // defaultValue={
                    //   item.endDate != null ? dayjs(item.endDate) : null
                    // }
                    className={'datepicker'}
                    onChange={val => {
                      setEndDateError('')
                      const date = val
                        ? `${val?.toDate()?.getFullYear()}-${(
                            val?.toDate()?.getMonth() + 1
                          )
                            ?.toString()
                            ?.padStart(2, '0')}-${val
                            .toDate()
                            ?.getDate()
                            .toString()
                            .padStart(2, '0')}`
                        : undefined
                      setEndDate(date)

                      if (startDate) {
                        if (
                          new Date(startDate).getTime() >=
                          new Date(date).getTime()
                        ) {
                          setEndDateError(
                            'The start date must be before the end date/The start date and end date cannot be the same.'
                          )
                        }
                      }

                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      item.endDate = val
                        ? `${val?.toDate()?.getFullYear()}-${(
                            val?.toDate()?.getMonth() + 1
                          )
                            ?.toString()
                            ?.padStart(2, '0')}-${val
                            .toDate()
                            ?.getDate()
                            .toString()
                            .padStart(2, '0')}`
                        : undefined
                    }}
                  />
                  {endDateError && (
                    <span className={'error'}>{endDateError}</span>
                  )}
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
      </Flex>
      <>
        <Flex marginRight={'0'}>
          <div style={{ flexDirection: 'column' }}>
            <CTextField
              disabled={isSubmitting}
              label={'Description'}
              multiline
              rows={7}
              placeholder={''}
              hasError={!!descriptionError}
              helperText={descriptionError}
              // defaultValue={item.description}
              value={description}
              paddingBottom={'30px'}
              onChange={event => {
                item.description = event.target.value
                setDescriptionError('')
                setDescription(event.target.value)
                if (event.target.value.length > 1000) {
                  setDescriptionError('Please edit to 1000 characters or fewer')
                } else {
                  setLength(event.target.value.length)
                }
              }}
            />
          </div>
        </Flex>
        <Length>
          <p>{length}/1000</p>
        </Length>
      </>
    </div>
  )
}
