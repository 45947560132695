import { useEffect, useRef } from 'react'
import { VideoStreamType } from '../../../models'
import { SelectedPeerStream } from '../../../controls'
import { isDesktop, isTablet } from '../../../utils/detect-screen'

interface PeerVideoProps {
  isSelfScreenStream?: boolean
  isSelfVideo?: boolean
  numberOfVideos: number
  onVideoClick?: (
    userId: string,
    videoStreamType: VideoStreamType,
    videoStream: MediaStream
  ) => void
  selectedPeerStream: SelectedPeerStream | null
  userId: string
  videoStream: MediaStream
  videoStreamType: VideoStreamType
}

export const PeerVideo = ({
  isSelfScreenStream,
  isSelfVideo,
  numberOfVideos,
  onVideoClick,
  userId,
  selectedPeerStream,
  videoStream,
  videoStreamType,
}: PeerVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    // Destructure the current video element from the videoRef
    const { current: video } = videoRef;

    // If no video element is found, exit the function
    if (!video) return;

    // Set the video to autoplay once the stream is available
    video.autoplay = true;

    // Assign the media stream to the video element's srcObject to play the stream
    video.srcObject = videoStream;

    // Effect runs when videoRef or videoStream changes
  }, [videoRef, videoStream]);

  const handleVideoClick = () => {
    // If the onVideoClick callback exists, invoke it with userId, videoStreamType, and videoStream
    onVideoClick?.(userId, videoStreamType, videoStream);
  };


  return (
    <video
      playsInline
      muted={isSelfScreenStream}
      ref={videoRef}
      onClick={handleVideoClick}
      style={{
        borderRadius: '10px',
        cursor: 'pointer',
        overflow: 'auto',
        marginLeft: selectedPeerStream ? '0' : 'auto',
        marginRight: selectedPeerStream ? '0' : 'auto',
        // margintop: selectedPeerStream ? '10px' : '50px',
        objectFit: 'cover',
        width: '100%',
        height: selectedPeerStream
          ?isDesktop() ?  (window.innerHeight - 195) : (window.innerHeight - 195) / 2 - (isTablet() ? 30 : 20)
          : videoStreamType === 'SCREEN_SHARE' ? 'auto' :'100%',
        ...(isSelfVideo && {
          transform: 'rotateY(180deg)',
        }),
      }}
    />
  )
}
