import React from 'react'
import styled from 'styled-components'
import { PlatformProfileModel } from '../../../models/platform/platform-profile.model.'

const NameStyle = styled.div<{ textcolor: string }>`
    display: flex;
    position: relative;
    left: 32px;
    top: 8px;

    > p {
        font-size: 20px;
        font-weight: 600;
        font-family: Inter;
        color: ${({ theme, textcolor }) => theme[textcolor]};
        line-height: 28px;
        margin-bottom: 20px;
        width: 100%;
    }

    > .icon {
        position: relative;
        left: 10px;
        cursor: pointer;
    }
`

/*
 * Renders the platform name within a styled component.
 * Accepts a platform prop to display the name with customizable text color.
 * The default text color is set to 'white100'.
 */
export interface Props {
  platform: PlatformProfileModel
}

export function Name(props: Props) {
  const { platform } = props

  return (
    <>
      <NameStyle textcolor={'white100'}>
        <p>{platform.name}</p>
      </NameStyle>
    </>
  )
}
