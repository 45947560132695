import React from 'react'
import styled from 'styled-components'
import { StartupProfileModel } from '../../../models/startup/startup-profile.model'
import { breakpoints } from '../../../config/global-styles'

const NameStyle = styled.div<{ textcolor: string }>`
  display: flex;
  position: relative;
  left: -30px;
  top: 20px;
  @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    position: static;
  }

  > p {
    font-size: 20px;
    font-weight: 600;
    font-family: Inter;
    color: ${({ theme, textcolor }) => theme[textcolor]};
    line-height: 28px;
    margin-bottom: 20px;
  }

  > .icon {
    position: relative;
    left: 10px;
    cursor: pointer;
  }
`

/*
 * Renders the startup name within a styled component.
 * Accepts a startup prop to display the name with customizable text color.
 * The default text color is set to 'white100'.
 */
export interface Props {
  startup: StartupProfileModel
}

export function Name(props: Props) {
  const { startup } = props

  return (
    <>
      <NameStyle textcolor={'white100'}>
        <p>{startup.name}</p>
      </NameStyle>
    </>
  )
}
