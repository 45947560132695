import { httpApi } from './http.api'
import { StartupModel } from '../models/startup/startup.model'

export interface PageResponse {
  success: boolean
  message: string
  data: {
    startups: StartupModel[]
    cursor: string
  }
}
export interface PageResponseSingle {
  success: boolean
  message: string
  data: {
    startup: StartupModel
  }
}
export interface StartupRequests {
  numberPerPage: number
  cursor: string
  search?: {
    q?: string
    profileIDs?: string[]
  }
}

export const findAllStartups = (req: StartupRequests): Promise<PageResponse> =>
  httpApi
    .get<PageResponse>(
      `startups?profiles=${req.search.profileIDs}&q=${req.search.q}&cursor=${req.cursor}&perPage=${req.numberPerPage}`
    )
    .then(({ data }) => data)

export const findSingleStartup = (id: string): Promise<PageResponseSingle> =>
  httpApi.get<PageResponseSingle>(`startups/${id}`).then(({ data }) => data)

