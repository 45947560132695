import { HTMLAttributes, useEffect } from 'react'
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import YouTube from 'react-youtube'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography, { TypographyProps } from '@mui/material/Typography'
import Link, { LinkProps } from '@mui/material/Link'
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import Markdown from 'react-markdown'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CodeProps } from 'react-markdown/lib/ast-to-react'
import remarkGfm from 'remark-gfm'

import {
  InlineMedia as I_InlineMedia,
  Message as IMessage,
  isMessageReceived,
  isInlineMedia,
} from '../../../models'
import { PeerNameDisplay } from '../peer'
import { CopyableBlock } from '../copy'
import { InlineMedia } from './inlineMedia'
import './message.sass'

export interface MessageProps {
  message: IMessage | I_InlineMedia
  showAuthor: boolean
  userId: string
}

const typographyFactory =
  (overrides: TypographyProps) => (args: HTMLAttributes<HTMLElement>) => {
    return <Typography {...args} {...overrides} />
  }

const linkFactory =
  (overrides: LinkProps) => (args: HTMLAttributes<HTMLElement>) => {
    return <Link {...args} {...overrides} />
  }

// Define a mapping of HTML elements to their corresponding styled components using a typography factory and a link factory
const componentMap = {
  // Heading components with varying sizes using the typography factory
  h1: typographyFactory({ variant: 'h1' }), // Maps <h1> to a styled component with 'h1' variant
  h2: typographyFactory({ variant: 'h2' }), // Maps <h2> to a styled component with 'h2' variant
  h3: typographyFactory({ variant: 'h3' }), // Maps <h3> to a styled component with 'h3' variant
  h4: typographyFactory({ variant: 'h4' }), // Maps <h4> to a styled component with 'h4' variant
  h5: typographyFactory({ variant: 'h5' }), // Maps <h5> to a styled component with 'h5' variant
  h6: typographyFactory({ variant: 'h6' }), // Maps <h6> to a styled component with 'h6' variant

  // Paragraph component styled as body text
  p: typographyFactory({ variant: 'body1' }), // Maps <p> to a styled component with 'body1' variant

  // Anchor component styled as a body text link
  a: linkFactory({
    variant: 'body1', // Uses 'body1' styling
    underline: 'always', // Link will always have an underline
    color: 'primary.contrastText', // Sets the link color to primary contrast text
    target: '_blank', // Opens links in a new tab
  }),

  // Custom code component for syntax highlighting
  code({ node, inline, className, children, style, ...props }: CodeProps) {
    // Use regex to extract the language from the className
    const match = /language-(\w+)/.exec(className || '');

    // If not inline and a language match is found, render a syntax highlighted block
    return !inline && match ? (
      <CopyableBlock>
        <SyntaxHighlighter
          children={String(children).replace(/\n$/, '')} // Converts children to a string and removes trailing newline
          language={match[1]} // Use the captured language for syntax highlighting
          style={materialDark} // Applies the material dark theme for highlighting
          PreTag="div" // Use a <div> for the preformatted tag
          {...props} // Spread any other props onto the SyntaxHighlighter
        />
      </CopyableBlock>
    ) : (
      // If inline or no language match, render a standard <code> element
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
};


const spaceNeededForSideDateTooltip = 850

const getYouTubeVideoId = (videoUrl: string) => {
  // Remove any leading or trailing whitespace from the URL
  const trimmedMessage = videoUrl.trim();

  // Use regular expressions to match the standard YouTube watch URL and the shortened youtu.be URL
  const matchArray =
    trimmedMessage.match(/https:\/\/www.youtube.com\/watch\?v=(\S{8,})$/) || // Matches full YouTube URL
    trimmedMessage.match(/https:\/\/youtu.be\/(\S{8,})$/); // Matches shortened YouTube URL

  // Return the video ID captured by the regex, or undefined if no match is found
  return matchArray?.pop();
};

const isYouTubeLink = (message: IMessage) => {
  return typeof getYouTubeVideoId(message.text) === 'string'
}

export const Message = ({ message, showAuthor, userId }: MessageProps) => {
  let backgroundColor: string

  if (message.authorId === userId) {
    backgroundColor = isMessageReceived(message) ? '#111A2C' : '#FFFFFF0D'
  } else {
    backgroundColor = '#FFFFFF0D'
  }

  const getTime = (date: Date) => {
    return date.getHours() + ':' + date.getMinutes()
  }

  return (
    <Box
      className="Message"
      sx={{
        backgroundColor,
        margin: 0.5,
        padding: '0.5em 0.75em',
        transition: 'background-color 1s',
        borderradius: 6,
        width: '250px',
        float: message.authorId === userId ? 'right' : 'left',
        color: '#ffffff',
      }}
    >
      {showAuthor && (
        <p
          style={{
            color: '#949494',
            marginTop: '10px',
            textAlign: message.authorId === userId ? 'right' : 'left',
            fontSize: '12px',
          }}
        >
          <PeerNameDisplay>{message.authorId}</PeerNameDisplay>
        </p>
      )}

      {isInlineMedia(message) ? (
        <InlineMedia magnetURI={message.magnetURI} />
      ) : isYouTubeLink(message) ? (
        <YouTube videoId={getYouTubeVideoId(message.text)} />
      ) : (
        <Markdown
          components={componentMap}
          remarkPlugins={[remarkGfm]}
          className={message.authorId === userId ? 'right-text' : 'left-text'}
        >
          {message.text}
        </Markdown>
      )}

      <p
        style={{
          color: '#949494',
          marginTop: '10px',
          textAlign: message.authorId === userId ? 'right' : 'left',
          fontSize: '12px',
        }}
      >
        {getTime(new Date(message.timeSent))}
      </p>
    </Box>
  )
}
