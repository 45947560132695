import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import React, { ReactElement, useEffect, useState } from 'react'
import { PostBox } from '../../components/post/box'
import { Page } from '../../components/structure'
import { FollowersBox, UserBox, WalletBox } from '../../components/user'
import { NothingFound } from '../../components/custom'
import { CButton, CSkeleton, CTab, CTabs } from '../../components/mui'
import { useAppSelector } from '../../redux/hooks'
import { EducationBox, ExperienceBox } from '../../components/user'
import { ArticleBox } from '../../components/article/box'
import { PostModel } from '../../models/post.model'
import { findAllPosts } from '../../apis/post.apis'
import { findAllArticles } from '../../apis/article.apis'
import { ArticleModel } from '../../models/article.model'
import { GetSingleUser } from '../../apis/user.api'
import { ProfileModel } from '../../models/profile.model'
import { breakpoints } from '../../config/global-styles'
import { ReactComponent as Back } from '../../assets/svg/back.svg'
import { CSvgButton } from '../../components/mui/SvgButton'

const TabBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 20;

  > span {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.black80};
  }
`

const About = styled.div`
  background: ${props => props.theme.navy80};
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  display: block;
  text-decoration: none;

  > .noData {
    width: 100%;
    font-size: 14px;
    color: #ffffffd9;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  > p {
    color: ${props => props.theme.white100};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
  }

  > .skills {
    display: inline-flex;

    > p {
      color: ${props => props.theme.white100};
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-top: 10px;
      margin-right: 10px;
    }
  }
`
//
export function UserProfilePage(): ReactElement {
  const { did } = useParams()

  const [loading, setLoading] = useState<boolean>(true)
  const [loadingProfile, setLoadingProfile] = useState<boolean>(true)
  const [theUser, setTheUser] = useState<ProfileModel>()
  const [viewType, setViewType] = useState<
    'about' | 'posts' | 'platforms' | 'articles'
  >('about')
  const user = useAppSelector(state => state.user)
  const [posts, setPosts] = useState<Array<PostModel>>([])
  const [articles, setArticles] = useState<Array<ArticleModel>>([])
  const [cursor, setCursor] = useState<string>('')
  const [cursorArticle, setCursorArticle] = useState<string>('')

  const handleChangeViewType = (event: any, newValue: any): void => {
    setViewType(newValue)
    if (newValue === 'posts') {
      setLoading(true)
      setLoadingProfile(true)

      getPosts()
    } else if (newValue === 'articles') {
      getAllArticles()
    }
  }

  const getAllArticles = () => {
    // Set loading states to true to indicate that data fetching is in progress
    setLoading(true);
    setLoadingProfile(true);

    // Fetch all articles with specified parameters
    findAllArticles({
      numberPerPage: 5, // Number of articles to retrieve per page
      cursor: cursorArticle, // Pagination cursor for fetching articles
      search: {
        q: '', // Search query (currently empty)
        profileIDs: [did], // Filter articles by profile ID
      },
    })
      .then(async res => {
        // On successful response, update the state with articles and cursor
        setLoading(false);
        setLoadingProfile(false);
        setArticles(res.data.articles);
        setCursorArticle(res.data.cursor);
      })
      .catch(err => {
        // If an error occurs, set loading states to false
        setLoading(false);
        setLoadingProfile(false);
        // Optional: You might want to log the error or show an alert here
      });
  };


  const getAllContents = () => {
    // Depending on the viewType, either fetch posts or articles
    if (viewType === 'posts') {
      getPosts(); // Fetch posts if viewType is 'posts'
    }
    if (viewType === 'articles') {
      getAllArticles(); // Fetch articles if viewType is 'articles'
    }

    // Fetch user details for the given user ID (user?.id) and DID
    GetSingleUser(user?.id ?? '', did)
      .then(res => {
        setTheUser(res.data.user); // Update the state with the fetched user data
        setLoading(false); // Set loading to false after the data is fetched
        setLoadingProfile(false); // Set profile loading to false
      })
      .catch(() => {
        // In case of an error, turn off loading states
        setLoading(false);
        setLoadingProfile(false);
      });
  };


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    getAllContents()
  }, [did])

  const getPosts = () => {
    // Fetch posts with specified number per page, cursor, and search parameters
    findAllPosts({
      numberPerPage: 5, // Limit the result to 5 posts per page
      cursor: cursor, // Use the current cursor for pagination
      search: {
        profileIDs: [did], // Filter posts by the user's profile ID (did)
        q: '', // Empty search query (fetches all posts for the user)
      },
    })
      .then(async res => {
        // Turn off loading indicators once the posts are retrieved
        setLoading(false);
        setLoadingProfile(false);

        // If there are posts in the response, append them to the existing posts array
        if (res && res.data.posts.length > 0) {
          setPosts(posts => [...posts, ...res.data.posts]); // Add new posts to the current list
          setCursor(res.data.cursor); // Update the cursor for the next page of posts
        }
      })
      .catch(err => {
        // Handle any errors during the API call (currently empty)
      });
  };


  const loadMoreHandler = () => {
    // Set loading states to true to indicate more content is being loaded
    setLoading(true);
    setLoadingProfile(true);

    // Determine which content type (posts or articles) to load based on viewType
    if (viewType === 'posts') {
      getPosts(); // Load more posts if viewType is 'posts'
    }
    if (viewType === 'articles') {
      getAllArticles(); // Load more articles if viewType is 'articles'
    }
  };

  const navigate = useNavigate()
  return (
    <Page
      title={'User Profile'}
      sidebar={
        <>
          <div style={{width:'100%',display:'flex',justifyContent:'end'}}>
            <CSvgButton
              icon={<Back />}
              customSvg={true}
              backgroundColor={'transparent'}
              backgroundColorHover={'transparent'}
              customColor={'white100'}
              customColorHover={'white100'}
              onClick={() => navigate(-1)}
            ></CSvgButton>
          </div>

          {/* <CSvgButton
              icon={<Back />}
              customSvg={true}
              loading={false}
              disabled={true}
              customColor={'white100'}
              backgroundColorHover={'transparent'}
              backgroundColor={'transparent'}
            /> */}
          <UserBox profile={theUser} />
          {/* {user.did !== '' && theUser?.id === user.id ? <WalletBox /> : null} */}
        </>
      }
      //
      sidebar2={
        <>
          <FollowersBox
            setTheUser={setTheUser}
            theUser={theUser}
            loading={loading}
            setLoading={loading => setLoading(loading)}
          />
        </>
      }
    >
      <TabBox>
        <CTabs
          value={viewType}
          onChange={handleChangeViewType}
          key={1}
          $background={'navy80'}
          $activeBG={'navy80'}
        >
          <CTab
            label={'About'}
            id={'view-tab-about'}
            aria-controls={'view-tabpanel-about'}
            value={'about'}
            disableTouchRipple
            $fullWidth
          />
          <CTab
            label={'Posts'}
            id={'view-tab-posts'}
            aria-controls={'view-tabpanel-posts'}
            value={'posts'}
            disableTouchRipple
            $fullWidth
          />
          <CTab
            label={'Articles'}
            id={'view-tab-articles'}
            aria-controls={'view-tabpanel-articles'}
            value={'articles'}
            disableTouchRipple
            $fullWidth
          />
          {/* <CTab
            label={'Platforms'}
            id={'view-tab-platforms'}
            aria-controls={'view-tabpanel-platforms'}
            value={'platforms'}
            disableTouchRipple
            $fullWidth
          /> */}
        </CTabs>
      </TabBox>

      {viewType === 'about' ? (
        theUser?.bio?.length ||
        theUser?.experiences?.length ||
        theUser?.educations?.length ||
        theUser?.skills?.length ? (
          <About>
            {theUser?.bio?.length ? (
              <>
                <h4>Bio</h4>
                {loadingProfile ? (
                  <CSkeleton
                    width={100}
                    height={10}
                    borderradius="12px"
                    marginbottom="3px"
                  />
                ) : (
                  <p>{decodeURIComponent(theUser?.bio ?? '')}</p>
                )}
              </>
            ) : null}

            <div style={{ marginTop: '20px' }}></div>
            {theUser?.skills?.length ? (
              <>
                <h4>Skills</h4>
                <div className="skills">
                  {loadingProfile ? (
                    <CSkeleton
                      width={100}
                      height={10}
                      borderradius="12px"
                      marginbottom="3px"
                    />
                  ) : (
                    theUser.skills.map((skill, index) => (
                      <p key={index}>
                        {skill}
                        {index < theUser.skills.length - 1 && ','}
                      </p>
                    ))
                  )}
                </div>
              </>
            ) : null}

            <div style={{ marginTop: '20px' }}></div>
            {theUser?.experiences?.length ? (
              <>
                <h4>Experiences</h4>
                <div style={{ marginTop: '15px' }}>
                  {loadingProfile
                    ? [1].map(i => <ExperienceBox loading key={i} />)
                    : theUser?.experiences?.map((experience, index) => (
                        <ExperienceBox experience={experience} key={index} />
                      ))}
                </div>
              </>
            ) : null}

            <div style={{ marginTop: '20px' }}></div>
            {theUser?.educations?.length ? (
              <>
                <h4>Educations</h4>
                <div style={{ marginTop: '15px' }}>
                  {loadingProfile
                    ? [1].map(i => <EducationBox loading key={i} />)
                    : theUser?.educations?.map((education, index) => (
                        <EducationBox education={education} key={index} />
                      ))}
                </div>
              </>
            ) : null}
          </About>
        ) : (
          <About>
            <p className="noData">This user hasn’t shared their story yet.</p>
          </About>
        )
      ) : viewType === 'posts' ? (
        loadingProfile ? (
          [1, 2, 3, 4].map(i => <PostBox loading key={i} />)
        ) : posts?.length === 0 ? (
          <NothingFound
            icon="hourglass_disabled"
            title="No Posts Found"
            padding={'30px'}
          />
        ) : (
          <div>
            {posts.map((post, i) => {
              return <PostBox loading={loading} post={post} key={i} type={2} />
            })}
            <CButton
              fullWidth
              background={'navy80'}
              color={'white100'}
              backgroundHover={'navy100'}
              loading={loading}
              onClick={loadMoreHandler}
            >
              Load More
            </CButton>
          </div>
        )
      ) : viewType === 'articles' ? (
        loadingProfile ? (
          [1, 2, 3, 4].map(i => <ArticleBox loading key={i} />)
        ) : articles?.length === 0 ? (
          <NothingFound
            icon="hourglass_disabled"
            title="No Articles Found"
            padding={'30px'}
          />
        ) : (
          <div>
            {articles.map((article, i) => {
              return (
                <ArticleBox
                  loading={loading}
                  article={article}
                  key={i}
                  type={2}
                />
              )
            })}
            <CButton
              fullWidth
              background={'navy80'}
              color={'white100'}
              backgroundHover={'navy100'}
              loading={loading}
              onClick={loadMoreHandler}
            >
              Load More
            </CButton>
          </div>
        )
      ) : (
        <></>
      )}
    </Page>
  )
}
