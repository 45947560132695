import React, { useState } from 'react'
import styled from 'styled-components'
import { StartupProfileModel } from '../../../models/startup/startup-profile.model'
import { platform } from 'os'
import { PlatformArticleModel } from '../../../models/platform/platform-article.model'
import { PlatformProfileModel } from '../../../models/platform/platform-profile.model.'
import { breakpoints } from '../../../config/global-styles'

const BannerStyle = styled.div`
    height: 202px;
    background: ${({ theme }) => theme.gray100};
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
  height: 110px;
  p{
    font-size: 14px !important;
    font-weight: 500;
    padding-top: 35px;
  }
}

    > p {
        font-size: 24px;
        font-weight: 600;
        font-family: Inter;
        color: ${({ theme }) => theme.white100};
        line-height: 32px;
        text-align: center;
        width: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    > img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
`

export interface Props {
  startup: StartupProfileModel,
}


/* 
Renders a banner component for a startup profile. 
Displays an image from the startup's cover URL 
if available, with a customizable height prop. 
*/
export function Banner(props: Props) {
  const { startup } = props

  return (
    <>
      <BannerStyle>
        {startup.cover != null && startup.cover !== '' && (
          <img loading={"lazy"} alt={''} src={`https://greenia.infura-ipfs.io/ipfs/${startup.cover}`} />
        )}
      </BannerStyle>
    </>
  )
}
