import { Link, useNavigate } from 'react-router-dom'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { create as createIPFS, IPFSHTTPClient } from 'ipfs-http-client'
import { useAppSelector } from '../../redux/hooks'
import { Card, Page } from '../../components/structure'
import { CButton } from '../../components/mui'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { ControllerDropZone, ControllerTextField } from '../../components/rhf'
import { GlobalContext, useGlobalStyleContext } from '../../contexts'
import { MyBlobToBuffer } from '../../utils/file'
import { ControllerTagField } from '../../components/rhf/tag-field'
import { ReactComponent as Publish } from '../../assets/svg/publish.svg'
import { PostCreateRequest, createPost } from '../../apis/post.apis'
import styled from 'styled-components'

const Flex = styled.div<{ marginRight: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  > *:first-child {
    flex-grow: 1;
    margin-right: ${({ marginRight }) => marginRight};
  }
`

const Length = styled.div`
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.gray50};
  display: block;
  text-decoration: none;
  margin-top: -40px;
  margin-right: 25px;
`

interface Inputs {
  description: string
  thumbnail: File[]
  tag: string
}

export function NewPostPage(): ReactElement {
  const user = useAppSelector(state => state.user)
  const navigate = useNavigate()
  const { makeAlert } = useContext(GlobalContext)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const {
    control,
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    defaultValues: {
      tag: '',
    },
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  // Initialize IPFS
  let ipfs: IPFSHTTPClient | undefined
  try {
    ipfs = createIPFS({
      url: 'https://ipfs.infura.io:5001/api/v0',
      headers: {
        authorization:
          'Basic ' +
          btoa(
            process.env.REACT_APP_INFURA_PROJECT_ID +
            ':' +
            process.env.REACT_APP_INFURA_API_KEY_SECRET
          ),
      },
    })
  } catch (error) {
    ipfs = undefined
  }

  // Function to handle form submission for creating a post
  const onSubmit: SubmitHandler<Inputs> = async data => {
    setIsSubmitting(true); // Set submitting state to true to indicate form is being submitted

    // Check if there's a thumbnail to upload but no IPFS client available, set an error
    if (watch('thumbnail') != null && ipfs == null) {
      setError('thumbnail', { message: 'File could not be uploaded' });
    }

    // Proceed if the form is valid
    if (isValid) {
      try {
        // Convert the uploaded thumbnail to a buffer
        MyBlobToBuffer(
          watch('thumbnail') != null ? watch('thumbnail')[0] : undefined,
          async (err, buff) => {
            if (err) {
              // Handle error if buffer conversion fails
              setError('thumbnail', { message: 'File could not be uploaded' });
              setIsSubmitting(false); // Reset submitting state
            } else {
              let upload;

              // If buffer is valid, upload it to IPFS
              if (buff != null) {
                upload = await ipfs?.add(buff);
              }

              // Get the path of the uploaded file or set it to an empty string if upload fails
              const thumbnailPath = upload?.path ?? '';

              // Create a post content object containing the description, attachment, and tags
              const content: PostCreateRequest = {
                body: encodeURIComponent(data.description), // Encode the post description
                attachment: thumbnailPath, // Set the uploaded thumbnail path
                tags: tags.map(x => x.text), // Extract tag text values
              };

              // Attempt to create the post with the given content
              createPost(content)
                .then(result => {
                  setIsSubmitting(false); // Reset submitting state
                  makeAlert('success', 'Post Created'); // Show success alert
                  navigate('/'); // Redirect to home page after successful creation
                })
                .catch(error => {
                  makeAlert('error', 'Error creating post'); // Show error alert if creation fails
                  setIsSubmitting(false); // Reset submitting state
                });
            }
          }
        );
      } catch (e) {
        // Handle unexpected errors during the post creation process
        makeAlert('error', 'Error creating post');
        setIsSubmitting(false); // Reset submitting state
      }
    } else {
      // Reset submitting state if form validation fails
      setIsSubmitting(false);
    }
  };


  const [tags, setTags] = useState<Array<{ text: string }>>([])

  const handleDelete = (index: number) => {
    const _tags = [...tags]
    _tags.splice(index, 1)
    setTags(_tags)
  }

// Function to handle the addition of a new tag
const handleAddition = (tag: any) => {
  // Regular expression to check for valid alphanumeric and special characters in the tag
  const chars = /[a-zA-Z0-9?><;,{}[\]\-_+=!@#$%\^&*|']/;

  // If the tag text is not empty and contains valid characters
  if (tag.text !== '' && chars.test(tag.text)) {
    // If there are 10 or fewer tags, add the new tag to the list
    if (tags.length <= 10) {
      setTags(tags => [...tags, { text: tag.text }]);
    } else {
      // If the maximum of 10 tags is reached, show an error alert
      makeAlert("error", "Tags input reached (MAX 10)");
    }
  }
};


  const handleResetForm = () => {
    reset()
    setTags([])
  }

  const [length, setLength] = useState<number>(0)
  const handleGetLength = (text: any) => {
    if (text.target.value === '') setLength(0)
    else setLength(text.target.value.length)
  }

  return (
    <Page
      title={'New Post'}
      sidebar={
        <div className={'back'}>
          <Link to={'/'}>
            <CButton
              size={'s'}
              background={'navy100'}
              backgroundHover={'navy100'}
              backgroundDisabled={'navy100'}
              color={'white100'}
              startIcon={'keyboard_arrow_left'}
            >
              <span style={{ marginLeft: '5px' }}>Back</span>
            </CButton>
          </Link>
        </div>
      }
      sidebar2={<></>}
    >
      <Card
        title={'Post'}
        footerAlignment={'row-reverse'}
        footer={[
          <CButton
            key={1}
            loading={isSubmitting}
            disabled={isSubmitting}
            margin="0 15px 0 0"
            form={'create-post'}
            background={'navy25'}
            backgroundHover={'navy25'}
            color={'white100'}
            type={'submit'}
            startIconSvg={<Publish />}
          >
            Create Post
          </CButton>,
          <CButton
            background={'gray40'}
            backgroundHover={'gray40'}
            color={'white100'}
            backgroundDisabled={'gray40'}
            onClick={handleResetForm}
          >
            Clear
          </CButton>,
        ]}
      >
        <form
          className="form"
          id="create-post"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            sx={{
              '@media (max-width:1130px)': {
                width: '100%',
                marginLeft: '0px',
                '.MuiGrid-item': {
                  paddingLeft: '0px',
                  width: '100%',
                  '.description': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  },
                },
              },
            }}
            container
            spacing={2}
          >
            <Grid item md={12} sm={12}>
              <Flex marginRight={'0'}>
                <ControllerTextField
                  controllerInstance={control}
                  controllerName="description"
                  rows={10}
                  multiline
                  onKeyDown={event => handleGetLength(event)}
                  errors={errors}
                  paddingBottom={'30px'}
                  disabled={isSubmitting}
                  label={'Description *'}
                  placeholder={'The full description of your post'}
                  controllerRules={{
                    required: {
                      value: true,
                      message:
                        'Please ensure all required fields, indicated with (*), are filled before submitting.',
                    },
                    maxLength: {
                      value: 1000,
                      message: 'Please edit to 1000 characters or fewer',
                    },
                  }}
                />
                <Length>
                  <p className={'length'}>{length}/1000</p>
                </Length>
              </Flex>

            </Grid>

            <Grid item md={12} sm={12}>
              <ControllerDropZone
                controllerInstance={control}
                controllerName="thumbnail"
                label={'Add An Image or Video (Optional)'}
                errors={errors}
                disabled={isSubmitting}
                maxFiles={1}
                maxSize={100}
                acceptedFiles={[
                  'image/jpg',
                  'image/jpeg',
                  'image/png',
                  'image/gif',
                  'video/mp4',
                ]}
              />
            </Grid>

            <Grid item md={12} sm={12} key={1}>
              <ControllerTagField
                controllerInstance={control}
                controllerName={`tag`}
                errors={errors}
                disabled={isSubmitting}
                label={'Title'}
                placeholder={'Tag (Optional)'}
                tags={tags.map((item: any, index: number) => ({
                  text: item.text,
                  id: index.toString(),
                }))}
                handleAddition={handleAddition}
                handleDelete={handleDelete}
              />
            </Grid>
          </Grid>
        </form>
      </Card>
    </Page>
  )
}
