import React from 'react'
import { LoginChildSec } from '../../newStructures/LoginChild.style'
import { CButtonTwo } from '../../mui/ButtonTwo'
import { UserModel } from '../../../models/user.model'
import { useAppDispatch } from '../../../redux/hooks'
import { set } from '../../../redux/slices/user'
import { isDesktop } from '../../../utils/detect-screen'
import { CSvgButton } from '../../mui/SvgButton'
import { ReactComponent as CloseLogin } from '../../../assets/svg/close-login.svg'

type Props = {
  loading: boolean
  onClose: () => void
  user: UserModel
  wallet: string
  did: string
}

export const Welcome = ({ loading, onClose, user, wallet, did }: Props) => {
  const dispatch = useAppDispatch()
  const handleOnClick = () => {
    dispatch(set({ ...user, wallet: wallet, did: did }))
    onClose()
  }

  return isDesktop() ? (
    <LoginChildSec>
      <CSvgButton
        icon={<CloseLogin />}
        disabled={false}
        onClick={onClose}
        customColor={'white100'}
        backgroundColorHover={'white'}
        backgroundColor="#140e26"
        style={{
          position: 'absolute',
          right: '0px',
          top: '0px',
          marginTop: '15px',
          marginRight: '15px',
        }}
      />
      <div className="top-heading">
        <h3 className="title">Welcome back!</h3>
        <div style={{ color: 'white' }} className="loading">
          You've successfully logged into Personia.
        </div>
      </div>
      <div className="loading">
        <CButtonTwo
          color={'#140E26'}
          background={'#39DBB2'}
          backgroundHover={'#39DBB2'}
          variant={'filled'}
          type={'button'}
          size="s"
          maxwidth={'70%'}
          loading={loading}
          fullWidth={true}
          onClick={handleOnClick}
          height={'40px'}
          fontSize="14"
          fontWeight={500}
        >
          Start Exploring
        </CButtonTwo>
      </div>
    </LoginChildSec>
  ) : (
    <LoginChildSec>
      <div className="top-heading">
        <h3 className="title">Welcome back!</h3>
        <div className="loading">
          You've successfully logged in. Start exploring your personalized
          social network.
        </div>
      </div>
      <div className="loading">
        <CButtonTwo
          color={'#140E26'}
          background={'#39DBB2'}
          backgroundHover={'#2aaa8a'}
          variant={'filled'}
          type={'button'}
          size="s"
          maxwidth={'296px'}
          loading={loading}
          fullWidth={true}
          onClick={handleOnClick}
          height={'40px'}
        >
          Enter Personia
        </CButtonTwo>
      </div>
    </LoginChildSec>
  )
}
