import React, {
  useContext,
  useState,
} from 'react'
import styled from 'styled-components'
import { CSvgButton } from '../../../mui/SvgButton'
import { CModal } from '../../../mui'
import EmojiPicker from 'emoji-picker-react'
import { EmojiClickData } from 'emoji-picker-react/dist/types/exposedTypes'
import { ReactComponent as Send } from '../../../../assets/svg/send.svg';
import { ReactComponent as Emoji } from '../../../../assets/svg/emoji.svg';

interface MessageFormProps {
  onMessageSubmit: (message: string) => void
  onMessageChange: (message: string) => void
  isMessageSending: boolean
}

const ChatMessageBoxStyle = styled.div`
  border: 1px solid ${(props) => props.theme.white30};
  border-radius: 16px;
  padding: 5px;
  display: flex;
  position: absolute;
  bottom: 0;
  width: 330px;
  margin: 0 -40px 20px auto;
  align-self: center;

  > textarea {
    width: 100%;
    background: transparent;
    border: none;
    color: ${(props) => props.theme.white100};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
    padding: 10px;
    text-align: left;
  }
`;

export const MessageForm = ({
  onMessageSubmit,
}: MessageFormProps) => {
  const [message, setMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Function to update the message state with the input value
  const handleSetMessage = (event: any) => {
    setMessage(event.target.value);
  };

  // State to control the visibility of the emoji modal
  const [emojiModal, setEmojiModal] = useState<boolean>(false);

  // Function to open the emoji selection modal
  const handleOpenEmoji = () => {
    setEmojiModal(true);
  };

  // Function to append the selected emoji to the message
  const handleSendEmoji = (emoji: EmojiClickData) => {
    setMessage(message + '' + emoji.emoji);
  };

  // Function to handle the submission of the message
  const handleSubmitMessage = () => {
    setIsSubmitting(true); // Set submitting state to true

    onMessageSubmit(message); // Submit the message
    setMessage(''); // Clear the message input
    setIsSubmitting(false); // Reset submitting state
  };

  // Function to handle keydown events in the message input
  const handleSetKeyDown = (event: any) => {
    // Check if the Enter key is pressed without the Shift key
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent default action (new line)
      setIsSubmitting(true); // Set submitting state to true

      onMessageSubmit(message); // Submit the message
      setMessage(''); // Clear the message input
      setIsSubmitting(false); // Reset submitting state
    }
  };


  return (
    <ChatMessageBoxStyle>
        <textarea
          rows={1}
          placeholder={'Type your messages...'}
          onChange={handleSetMessage}
          onKeyDown={handleSetKeyDown}
          value={message}></textarea>

      <CSvgButton
        disabled={false}
        loading={false}
        backgroundColor={'transparent'}
        backgroundColorHover={'transparent'}
        icon={<Emoji />}
        onClick={handleOpenEmoji}
      />
      <CSvgButton
        onClick={handleSubmitMessage}
        disabled={isSubmitting}
        loading={isSubmitting}
        backgroundColor={'transparent'}
        backgroundColorHover={'transparent'}
        customColor={'white100'}
        icon={<Send />}
      />

      <CModal
        width={'50%'}
        open={emojiModal}
        onClose={() => {
          setEmojiModal(false);
        }}
        title='Emoji'>
        <EmojiPicker
          onEmojiClick={(emoji: EmojiClickData) => {
            handleSendEmoji(emoji);
          }} />
      </CModal>
    </ChatMessageBoxStyle>
  )
}
