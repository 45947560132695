import { useEffect, useState } from 'react'
import { sleep } from '../../../utils'
import {
  ConnectionTest,
  ConnectionTestEvent,
  ConnectionTestEvents,
  TrackerConnection,
} from '../../../services'

export interface ConnectionTestResults {
  hasHost: boolean
  hasRelay: boolean
  trackerConnection: TrackerConnection
}

const rtcPollInterval = 20 * 1000
const trackerPollInterval = 5 * 1000

export const useConnectionTest = () => {
  const [hasHost, setHasHost] = useState(false)
  const [hasRelay, setHasRelay] = useState(false)
  const [trackerConnection, setTrackerConnection] = useState(
    TrackerConnection.SEARCHING
  )

  useEffect(() => {
    // Function to test RTC connection and listen for changes in host and relay status
    const checkRtcConnection = async () => {
      const connectionTest = new ConnectionTest()

      // Event listener for host changes
      const handleHasHostChanged = ((event: ConnectionTestEvent) => {
        setHasHost(event.detail.hasHost)
        connectionTest.removeEventListener(
          ConnectionTestEvents.HAS_HOST_CHANGED,
          handleHasHostChanged
        )
      }) as EventListener

      // Add listener for host status change
      connectionTest.addEventListener(
        ConnectionTestEvents.HAS_HOST_CHANGED,
        handleHasHostChanged
      )

      // Event listener for relay changes
      const handleHasRelayChanged = ((event: ConnectionTestEvent) => {
        setHasRelay(event.detail.hasRelay)
        connectionTest.removeEventListener(
          ConnectionTestEvents.HAS_RELAY_CHANGED,
          handleHasRelayChanged
        )
      }) as EventListener

      // Add listener for relay status change
      connectionTest.addEventListener(
        ConnectionTestEvents.HAS_RELAY_CHANGED,
        handleHasRelayChanged
      )

      // Remove listeners after rtcPollInterval time
      setTimeout(() => {
        connectionTest.removeEventListener(
          ConnectionTestEvents.HAS_HOST_CHANGED,
          handleHasHostChanged
        )
        connectionTest.removeEventListener(
          ConnectionTestEvents.HAS_RELAY_CHANGED,
          handleHasRelayChanged
        )
      }, rtcPollInterval)

      // Initialize RTC connection test
      try {
        await connectionTest.initRtcPeerConnectionTest()
      } catch (e) {
        setHasHost(false)
        setHasRelay(false)
        console.error(e)
      }

      return connectionTest
    }

    // Continuously check RTC connection at intervals
    ;(async () => {
      while (true) {
        const connectionTest = await checkRtcConnection()
        await sleep(rtcPollInterval)
        connectionTest.destroyRtcPeerConnectionTest()
      }
    })()

    // Continuously test tracker connection at intervals
    ;(async () => {
      while (true) {
        try {
          const connectionTest = new ConnectionTest()
          const trackerConnectionTestResult =
            connectionTest.testTrackerConnection()

          setTrackerConnection(trackerConnectionTestResult)
        } catch (e) {
          setTrackerConnection(TrackerConnection.FAILED)
        }

        await sleep(trackerPollInterval)
      }
    })()
  }, []) // Empty dependency array ensures the effect runs only once when the component mounts

  return {
    connectionTestResults: { hasHost, hasRelay, trackerConnection },
  }
}
